<template>
    <PageLayout>
        <template v-slot:default>
            <div class="d-flex justify-space-between mb-6">
                <div class="d-flex align-center">
                    <h1>{{ $t('accounting.dashboard.title') }}</h1>
                </div>
            </div>
            <TabsNav :items="breadcrumbs()[1].items" />

            {{ balance }}

        </template>
        <template v-slot:side>
            <Side />
        </template>
    </PageLayout>
</template>
<script>
import PageLayout from "@/components/Leentech/PageLayout.vue";
import WarehouseHeader from "@/components/Warehouse/WarehouseHeader.vue";
import Side from "@/components/Warehouse/Side.vue";
import TabsNav from "@/components/Leentech/TabsNav.vue";
import NavMixin from "@/plugins/mixins/Nav";
import BarChart from "@/plugins/charts/BarChart";

export default {
    name: "Sale",

    components: {
        TabsNav,
        Side,
        PageLayout
    },

    mixins: [NavMixin],

    data() {
        return {
            balance: 0,
        }
    },

    computed: {
    },

    mounted() {
        this.init();
    },


    methods: {
        init() {
            this.fetchBalance().then((balance) => {
                this.balance = balance;
            });
        },

        fetchBalance () {
            return new Promise((resolve, reject) => {
                this.$http.get('admin/accounting/report/balance')
                    .then(response => {
                        resolve(response.body.balance);
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        }
    }
}
</script>
